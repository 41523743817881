<template   >
  <v-card class="border" style="max-height:inherit; overflow-y:scroll"  >
    <v-btn @click="close" elevation="0" color="red" class="close-btn align-self-end"
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <v-card-title v-if="action.ADD" class="title">
      Agregar proveedor de gasto
    </v-card-title>
    <v-card-title v-else class="title"> Modificar proveedor de gasto </v-card-title>

    <v-form @submit.prevent="action.ADD ? add : edit">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderName"
              :rules="rules"
              outlined
              dense
              required
              label="Nombre"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderLegalAddress"
              :rules="rules"
              outlined
              dense
              required
              label="Dirección legal"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              outlined
              dense
              required
              label="Actividad economica:*"
              :items="activities"
              :rules="rules"
              attach
              v-model="provider.ProviderLegalSector"
              item-text="Valores"
              item-value="Codigo"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              outlined
              dense
              required
              :items="size"
              label="Tamaño del contribuyente"
              attach
              v-model="provider.ProviderLegalTaxPayerSize"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="provider.ProviderLegalEntity"
              :items="entity"
              :rules="rules"
              outlined
              dense
              required
              attach
              label="Entidad jurídica"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderLegalNRC"
              :rules="rules"
              outlined
              dense
              required
              attach
              label="NRC Legal"
              @input="nrcMask"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="provider.ProviderLegalNIT"
              :rules="rules"
              outlined
              dense
              required
              label="NIT Legal"
              @input="maskNit"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                outlined
                dense
                :items="countryList"
                attach
                item-text="name"
                item-value="name"
                v-model="provider.ProviderCountry"
                required
                :rules="required"
                @change="getStates"
                label="País*"
              >
              </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                outlined
                dense
                required
                :rules="required"
                v-if="provider.ProviderCountry == 'El Salvador'"
                :items="departments"
                v-model="provider.ProviderState"
                label="Estado/Departamento*"
                item-text="Valores"
                item-value="Codigo"
                attach
              >
              </v-autocomplete>
              <v-autocomplete
                outlined
                dense
                required
                :rules="required"
                v-else
                v-model="provider.ProviderState"
                label="Estado/Departamento*"
                :items="stateList"
                item-text="name"
                @change="getCities"
                attach
              >
                <template v-slot:no-data>
                  <div class="px-4">{{ noDataLabelStates }}</div>
                </template>
              </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete
                outlined
                dense
                required
                :rules="required"
                :items="municipalities"
                v-if="provider.ProviderCountry == 'El Salvador'"
                :readonly="!provider.ProviderState"
                v-model="provider.ProviderCity"
                label="Ciudad*"
                item-text="Valores"
                item-value="Codigo"
                attach
              />
              <div v-else>
                <v-autocomplete
                  outlined
                  dense
                  required
                  :rules="required"
                  v-if="cityList.length > 0 || noDataLabelCities === 'Cargando...'"
                  v-model="provider.ProviderCity"
                  label="Ciudad*"
                  item-text="name"
                  :readonly="!provider.ProviderState"
                  :items="cityList"
                  attach
                >
                  <template v-slot:no-data>
                    <div class="px-4">{{ noDataLabelCities }}</div>
                  </template>
                </v-autocomplete>
                <v-text-field
                  outlined
                  dense
                  required
                  :rules="required"
                  v-else
                  v-model="provider.ProviderCity"
                  label="Ciudad*"
                  placeholder="Digite la ciudad del cliente"
                />
              </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete outlined dense attach :items="tipoIngresoProveedores" item-text="Valores" item-value="Codigo" v-model="provider.TipoIngresos" label="Tipo de Costo / Gasto">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-autocomplete outlined dense attach :items="sectorProveedores" item-text="Valores" item-value="Codigo" v-model="provider.SectorCSV" label="Sector">
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox
            v-model="provider.LegalAgentRetain"
            label="Es agente de retención 1%"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox
            v-model="provider.LegalReteRenta"
            label="Retiene 10% renta"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn class="btn-add" v-if="action.DEL && $store.getters['rolesUser/getCustomRole'](215)" @click="del">Eliminar</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="btnsave" v-if="action.ADD && $store.getters['rolesUser/getCustomRole'](213)" type="submit" @click="add">Guardar</v-btn>
        <v-btn class="btnsave" v-if="action.EDIT && $store.getters['rolesUser/getCustomRole'](214)" type="submit" @click="edit">Editar</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {
  nitForHomologation as nitMask,
  duiForHomologation as duiMask,
  nrc as nrcMask,
  applyDUIMaskOutOfInput,
  applyNITMaskOutOfInput,
} from "@/helpers/masks";
import { mapState, mapGetters } from "vuex";
export default {
  name: "ExpenseProviderDialog",

  props: ["action", "newSelectedProvider"],

  components: {},

  data() {
    return {
      rules: [(v) => v !== null || "Valor es requerido"],
      entity: ["PERSONA NATURAL", "SOCIEDAD ANÓNIMA", "CORPORACIÓN"],
      size: ["PEQUEÑO", "MEDIANO", "GRANDE"],
      stateList: [],
      cityList: [],
      noDataLabelStates: "Seleccione un país",
      noDataLabelCities: "No hay ciudades disponibles",
      provider: this.newSelectedProvider
        ? this.newSelectedProvider
        : {
            ProviderName: "",
            ProviderLegalAddress: "",
            ProviderLegalSector: "",
            ProviderLegalTaxPayerSize: "",
            ProviderLegalEntity: "",
            ProviderLegalNRC: "",
            ProviderLegalNIT: "",
          },
    };
  },

  watch: {
    newSelectedProvider(data) {
      if (data != undefined && data != null) {
        this.provider = this.newSelectedProvider;
      } else {
        this.provider = {
          ProviderName: "",
          ProviderLegalAddress: "",
          ProviderLegalSector: "",
          ProviderLegalTaxPayerSize: "",
          ProviderLegalEntity: "",
          ProviderLegalNRC: "",
          ProviderLegalNIT: "",
        };
      }
    },
    /*"provider.ProviderLegalTaxPayerSize"() {
      if (this.provider.ProviderLegalTaxPayerSize == "GRANDE") {
        this.provider.LegalAgentRetain = true
      }
      else {
        this.provider.LegalAgentRetain = false
      }
    }*/
  },

  computed: {
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    ...mapGetters(["getCatalogMH"]),
    tipoIngresoProveedores() {
      return this.getCatalogMH('TipoIngresoProveedores')
    },
    sectorProveedores() {
      return this.getCatalogMH('SectorProveedores')
    },
    ID_Business() {
      return JSON.parse(localStorage.getItem("user")).businessid != undefined
        ? JSON.parse(localStorage.getItem("user")).businessid
        : 0;
    },
    ID_Branch() {
      return JSON.parse(localStorage.getItem("user")).branch != undefined
        ? JSON.parse(localStorage.getItem("user")).branch
        : 0;
    },
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
    checkCountry() {
      if (this.provider.ProviderCountry == "El Salvador") return true;
      else return false;
    },
    departments() {
      return this.getCatalogMH("Departamento");
    },
    municipalities() {
      const municipalities = this.getCatalogMH("Municipio");
      if (this.provider.ProviderState) {
        const department = municipalities.filter(
          (mun) => mun.Departamento == this.provider.ProviderState
        );
        return department;
      }
      return [];
    },
    countriesMh() {
      return this.getCatalogMH("Pais");
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
  },

  methods: {
    async getStates() {
      /*if (this.provider.ProviderCountry == "El Salvador") {
        this.form.phonenumber = this.form.phonenumber.substring(0, 8);
        return [];
      }*/
      if (this.provider.ProviderCountry) {
        this.noDataLabelStates = "Cargando...";
        this.stateList = [];

        this.stateList = await this.$API.general.getStates(this.provider.ProviderCountry);
        this.noDataLabelStates = "No hay Estados disponibles";
      }
    },
    async getCities() {
      if (this.provider.ProviderCountry == "El Salvador") return [];
      if (this.provider.ProviderState) {
        this.noDataLabelCities = "Cargando...";
        this.cityList = [];

        this.cityList = await this.$API.general.getCities(this.provider.ProviderState);

        this.noDataLabelCities = "No hay Ciudades disponibles";
      }
    },
    async maskNit(e) {
      if (e.which === 8) return false;
      if (this.provider.ProviderLegalNIT.replace("-", "").length >= 14) {
        this.provider.ProviderLegalNIT = await nitMask(
          this,
          this.provider.ProviderLegalNIT
        );
      } else {
        this.provider.ProviderLegalNIT = await duiMask(
          this,
          this.provider.ProviderLegalNIT
        );
      }
    },
    async nrcMask() {
      this.provider.ProviderLegalNRC = await nrcMask(
        this,
        this.provider.ProviderLegalNRC
      );
    },
    /*async nitMask() {
      this.provider.ProviderLegalNIT = await nitMask(
        this,
        this.provider.ProviderLegalNIT
      );
    },*/
    applyMasks() {
      this.provider.ProviderLegalNIT &&
        (this.provider.ProviderLegalNIT = applyNITMaskOutOfInput(
          this.provider.ProviderLegalNIT
        ));
      this.provider.ProviderLegalNRC &&
        (this.provider.ProviderLegalNRC = applyDUIMaskOutOfInput(
          this.provider.ProviderLegalNRC
        ));
    },
    add() {
      this.$emit("handleAction", this.dataToSend("add"));
      this.clean();
    },
    edit() {
      this.$emit("handleAction", this.dataToSend("edit", this.newSelectedProvider));
      this.clean();
    },
    del() {
      this.$emit("handleAction", this.dataToSend("del", this.newSelectedProvider));
      this.clean();
    },
    close() {
      this.$emit("handleAction");
      this.clean();
    },
    dataToSend(action) {
      this.provider.action = action;
      return this.provider;
    },

    clean() {
      this.provider = {
        ProviderName: "",
        ProviderLegalAddress: "",
        ProviderLegalSector: "",
        ProviderLegalTaxPayerSize: "",
        ProviderLegalEntity: "",
        ProviderLegalNRC: "",
        ProviderLegalNIT: "",
      };
      this.applyMasks();
    },
  },
};
</script>

<style scoped>
.sized {
  min-width: 400px !important;
}

.close-btn {
  color: white !important;
  position: relative !important;
  float: right !important;
}

.border {
  border-radius: 15px !important;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
  margin: 0;
  padding: 0;
}

.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btndelete {
  background-color: red !important;
  color: white;
}

.title {
  font-weight: bold !important;
  font-size: 25px !important;
}
</style>
